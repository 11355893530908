<template>
  <div>
    <van-skeleton v-if="loading.content" title :row="3" class="mt-4" />
    <template v-else>
      <van-steps :active="step">
        <van-step>Informasi Toko</van-step>
        <van-step>Aktivasi Wallet</van-step>
        <van-step>Selesai</van-step>
      </van-steps>
      <div v-if="step == 0" class="van-ellipsis--l2 p-2" style="white-space: pre-wrap;">Kamu akan bergabung sebagai <strong>Merchant VENTENY</strong>. Untuk pendaftaran, mohon isi data dibawah ini.<br/><br/></div>
      <div v-if="step == 1" class="van-ellipsis--l2 p-2" style="white-space: pre-wrap;">Yeay, kamu berhasil mendaftar di <strong>VENTENY</strong>! 🎉🎉🎉<br/><br/>Untuk proses selanjutnya, kamu perlu untuk aktivasi wallet untuk menerima pembayaran dari customer kamu. Nomor yang kamu daftarkan akan dipakai untuk aktivasi wallet. Silakan isi PIN yang akan kamu gunakan dan OTP yang akan kami kirim ke nomormu. Pastikan <strong>saldo</strong> tersedia di nomor handphonemu.<br/><br/></div>
      <!-- <h3>Formulir Pendaftaran</h3> -->
      <div class="van-hairline--top"></div><br/>
      <van-cell-group :border="false" v-if="step < 2">
        <van-form validate-trigger="onBlur" @submit="nextStepHandler(step)" class="mb-4">
          <span v-for="(item, index) in fields"
            :key="index">
            <van-divider v-if="index > 0"/>
            <span class="van-field__label ml-4">{{item.name}}<span class="text-danger" v-if="item.is_required">*</span></span>
            <van-field v-if="item.type != 'otp'"
              label-width="130"
              v-model="form[item.field_name]"
              :placeholder="item.placeholder"
              :type="item.type"
              :maxlength="item.limit"
              show-word-limit
              :rules="item.rules">
              <template v-if="item.type == 'select'" #input>
                <van-radio-group v-model="form[item.field_name]">
                  <van-radio
                    checked-color="#FB7418"
                    class="mt-2" v-for="(name, index) in item.selection_list" :name="name" :key="index">{{ name }}</van-radio>
                </van-radio-group>
              </template>
              <template v-if="item.btn_show_hide_value" #button>
                <van-icon v-if="!item.is_visible_password" @click="handlerShowHidePassword(index)" name="eye-o" />
                <van-icon v-else @click="handlerShowHidePassword(index)" name="closed-eye" />
              </template>
            </van-field>
            <template v-if="item.type == 'otp'">
              <van-password-input
                :value="form[item.field_name]"
                v-model="form[item.field_name]"
                :length="item.limit"
                :mask="item.hide_value"
                :focused="showKeyboard[item.field_name]"
                @focus="showKeyboard[item.field_name] = true; addBodyHeight();"
              />
              <van-field v-if="item.type == 'otp'" type="hidden" :rules="item.rules" v-model="form[item.field_name]">
              </van-field>
              <van-number-keyboard
                @input="onInput($event, item.field_name)"
                :show="showKeyboard[item.field_name]"
                @delete="onDelete(item.field_name)"
                @blur="showKeyboard[item.field_name] = false; subtractBodyHeight();"
                @close="subtractBodyHeight();"
                @hide="subtractBodyHeight();"
              />
            </template>
            <small class="van-field__label ml-4" v-if="item.description"><i>{{ item.description }}</i></small>
            <template v-if="item.can_resend_action">
              <span class="ml-4 mt-2" v-if="waitingSecond">Tunggu <strong>{{waitingSecond}}</strong> sebelum mengulangi proses ini.</span>
              <a v-else href="javascript:;" @click="handlerResendAction(item.function_name)" class="ml-4 mt-2">{{item.resend_action_str}}</a>
            </template>
          </span>
          <div class="floating-cta mt-4">
            <van-button class="w-full main-cta" type="brand">Selanjutnya</van-button>
          </div>
        </van-form>
      </van-cell-group>
      <template v-else>
        <van-row>
          <van-col span="8">&nbsp;</van-col>
          <van-col span="8">
            <!-- <van-empty
              class="custom-image"
              image="https://img01.yzcdn.cn/vant/leaf.jpg"
              description="Berhasil! Proses pendaftaran telah selesai. Kamu bisa download aplikasi VENTENY di Google Playstore ataupun App Store."
            /> -->
            <div class="van-empty">
              <div class="van-empty__image"><img src="@/assets/images/victor.png"></div>
            </div>
          </van-col>
          <van-col span="8">&nbsp;</van-col>
        </van-row>
        <div class="pl-20 pr-20" align="center">
          <h3>Yeay! Proses pendaftaran telah selesai 🥳🎉</h3>
          <p align="center">Kamu bisa download aplikasi VENTENY di Google Playstore ataupun App Store.</p>
          <div class="flex justify-center items-center mb-8">
            <a href="https://venteny.app.link/download" class="mr-5 pt-1">
              <img src="https://cms.vntny.id/uploads/app_store_95825c6bbe.png">
            </a>
            <a href="https://venteny.app.link/download" class="mr-5 pt-1">
              <img src="https://cms.vntny.id/uploads/google_play_7de3b79470.png"></a>
            </div>
        </div>
      </template>
      <van-popup v-model="show_otp_register" round position="bottom" :style="{ height: '75%' }">
        <h3 align="center" class="mt-3">Verifikasi OTP</h3>
        <van-form validate-trigger="onBlur" @submit="nextStepHandler(step)" class="mt-2 mb-2">
          <div align="center" class="van-ellipsis--l2 p-2" style="white-space: pre-wrap;">Kami telah mengirimkan OTP untuk pendaftaran akun kamu. Silakan masukkan kode OTP yang Anda terima.<br/><br/></div>
          <span class="van-field__label ml-4">OTP<span class="text-danger">*</span></span>
          <van-password-input
            :value="form.otp"
            v-model="form.otp"
            :length="4"
            :mask="false"
            :focused="showKeyboard.otp_register"
            @focus="showKeyboard.otp_register = true"
          />
          <van-field type="hidden" :rules="[{ required: true, message: 'Kode OTP tidak boleh kosong' }, { validator: checkValidOTP, message: 'Kode OTP tidak valid' }]" v-model="form.otp"></van-field>
          <span class="ml-4 mt-2" v-if="waitingSecond">Tunggu <strong>{{waitingSecond}}</strong> sebelum kirim ulang OTP</span>
          <a v-else href="javascript:;" @click="triggerRequestOTPRegister" class="ml-4 mt-2">Kirim ulang OTP</a>
          <van-button class="w-full main-cta mt-4" type="brand">Lanjutkan</van-button>
          <van-number-keyboard
            @input="onInputOTPRegister"
            :show="showKeyboard.otp_register"
            @delete="onDeleteRegister"
            @blur="showKeyboard.otp_register = false"
          />
        </van-form>
      </van-popup>
    </template>
  </div>
</template>
<script>
/* eslint-disable no-async-promise-executor */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable prefer-object-spread */
import {
  VERIFY_PHONE_NUMBER, REGISTER_MERCHANT, GET_OTP_REGISTER, GET_OTP_WALLET, REGISTER_WALLET,
} from '../../../store/modules/merchant';
import rsa from '../../../library/rsa-encrypt';
import { mapGetters } from 'vuex';
import passwordComplexity from '../../../library/password-complexity';

export default {
  data() {
    return {
      loading: {
        content: false,
      },
      showKeyboard: {
        otp_register: false,
      },
      show_otp_register: false,
      value: '',
      step: 0,
      fields: [],
      form: {
        otp: '',
      },
      step1_fields: [
        {
          name: 'Nama Lengkap',
          placeholder: 'Masukkan nama lengkap Anda',
          field_name: 'full_name',
          type: 'text',
          is_required: true,
        },
        {
          name: 'Nama Toko',
          placeholder: 'Masukkan nama toko Anda',
          field_name: 'shop_name',
          description: 'Nama toko akan terlihat ketika pembayaran',
          type: 'text',
          is_required: true,
        },
        {
          name: 'Alamat Toko',
          placeholder: 'Masukkan alamat toko Anda',
          field_name: 'address',
          type: 'text',
          is_required: true,
        },
        {
          name: 'No. Handphone',
          placeholder: 'Masukkan nomor handphone Anda. Contoh: 081234567890',
          field_name: 'phone_number',
          type: 'number',
          is_required: true,
          limit: 15,
          custom_rules: [
            {
              validator: (val) => {
                return new Promise((resolve) => {
                  const loading_toast = this.$toast.loading('Memvalidasi nomor handphone...');
                  this.$store.dispatch(VERIFY_PHONE_NUMBER, {
                    phone_number: val,
                  }).then(() => {
                    loading_toast.clear();
                    resolve(true);
                  }).catch(() => {
                    loading_toast.clear();
                    resolve(false);
                  });
                });
              },
              message: 'Nomor handphone sudah terdaftar.',
            },
          ],
        },
        {
          name: 'Email',
          placeholder: 'Masukkan alamat Anda. Contoh: agus@gmail.com',
          field_name: 'email',
          type: 'email',
          is_required: true,
          custom_rules: [
            {
              validator: (value) => /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/g.test(value),
              message: 'Alamat email tidak valid.',
            },
          ],
        },
        {
          name: 'Kata Sandi',
          placeholder: 'Masukkan kata sandi Anda',
          field_name: 'password',
          type: 'password',
          is_required: true,
          btn_show_hide_value: true,
          is_visible_password: false,
          custom_rules: [
            {
              validator: (value) => passwordComplexity(value),
              message: 'Kata sandi harus mempunyai minimal satu angka, satu huruf besar, dan satu karakter spesial.',
            },
            {
              validator: (value) => this.form.confirm_password && this.form.confirm_password === value,
              message: 'Kata sandi dan konfirmasi kata sandi harus sama.',
            },
          ],
        },
        {
          name: 'Konfirmasi Kata Sandi',
          placeholder: 'Ketik ulang kata sandi Anda',
          field_name: 'confirm_password',
          type: 'password',
          is_required: true,
          btn_show_hide_value: true,
          is_visible_password: false,
          custom_rules: [
            {
              validator: (value) => this.form.password === value,
              message: 'Kata sandi dan konfirmasi kata sandi harus sama.',
            },
          ],
        },
      ],
      step2_fields: [
        {
          name: 'PIN',
          field_name: 'pin',
          type: 'otp',
          is_required: true,
          hide_value: true,
          description: 'PIN akan digunakan untuk mengakses wallet kamu.',
        },
        {
          name: 'OTP',
          field_name: 'otp',
          type: 'otp',
          is_required: true,
          deleteable_from: 2,
          limit: 7,
          hide_value: false,
          can_resend_action: true,
          resend_action_str: 'Kirim ulang OTP',
          function_name: 'resendOTPWallet',
        },
      ],
      waitingCountdownOTP: false,
      waitingSecond: 0,
      timer: null,
      isValidOtpRegister: true,
    };
  },
  computed: {
    ...mapGetters(['merchant_profile']),
  },
  mounted() {
    this.$parent.navbarTitle = 'Registrasi Merchant';
    if (this.merchant_profile?.api_token) {
      this.form = Object.assign({}, this.merchant_profile);
      this.step = 0;
      this.nextStep();
      return;
    }
    this.fields = this.step1_fields.map((v) => {
      v.rules = [{ required: v.is_required, message: 'Kolom ini wajib diisi' }];
      if (v.custom_rules) {
        v.rules.push(...v.custom_rules);
      }
      return v;
    });
  },
  methods: {
    async handlerRegisterMerchant() {
      const loading_toast = this.$toast.loading('Mendaftarkan data Anda...');
      try {
        const obj = Object.assign({}, this.form);
        obj.password = rsa.encrypt(obj.password, 'base64');
        obj.confirm_password = rsa.encrypt(obj.confirm_password, 'base64');
        await this.$store.dispatch(REGISTER_MERCHANT, obj).then(() => {
          this.show_otp_register = false;
          clearInterval(this.timer);
          this.waitingSecond = null;
          this.waitingCountdownOTP = false;
          this.nextStep();
          this.resendOTPWallet({ action: '' });
        });
        loading_toast.clear();
      } catch (error) {
        loading_toast.clear();
        // console.log(error.response);
        const msg = error.response?.data?.data?.message ? error.response?.data?.data?.message : 'Proses gagal. Silakan coba beberapa saat lagi.';
        this.$toast.fail({
          message: msg,
          duration: 5000,
        });
      }
    },
    async triggerRequestOTPRegister() {
      if (this.waitingCountdownOTP) {
        return;
      }
      const loading_toast = this.$toast.loading('Meminta\nOTP...');
      try {
        await this.$store.dispatch(GET_OTP_REGISTER, {
          phone_number: this.form.phone_number,
        });
        this.show_otp_register = true;
        this.waitingCountdownOTP = true;
        this.startTimer();
        loading_toast.clear();
      } catch (error) {
        loading_toast.clear();
        // console.log(error);
        const msg = error.response?.data?.data?.message ? error.response?.data?.data?.message : 'Proses gagal. Silakan coba beberapa saat lagi.';
        this.$toast.fail({
          message: msg,
          duration: 5000,
        });
      }
    },
    async handlerRegisterWallet() {
      const loading_toast = this.$toast.loading('Mendaftarkan\nwallet...');
      try {
        await this.$store.dispatch(REGISTER_WALLET, this.form);
        this.nextStep();
        loading_toast.clear();
      } catch (error) {
        loading_toast.clear();
        const msg = error.response?.data?.data?.message ? error.response?.data?.data?.message : 'Proses gagal. Silakan coba beberapa saat lagi.';
        this.$toast.fail({
          message: msg,
          duration: 5000,
        });
      }
    },
    async resendOTPWallet({ action = 'resend_otp' } = {}) {
      if (this.waitingCountdownOTP) {
        return;
      }
      const loading_toast = this.$toast.loading('Meminta\nOTP...');
      try {
        await this.$store.dispatch(GET_OTP_WALLET, {
          phone_number: this.merchant_profile.phone_number,
          action,
        }).then(({ otp }) => {
          this.form.otp = otp.tokenPrefix;
          loading_toast.clear();
        });
        this.waitingCountdownOTP = true;
        this.startTimer();
      } catch (error) {
        loading_toast.clear();
        this.$toast.fail({
          msg: 'Proses gagal. Ulangi beberapa saat lagi.',
          duration: 5000,
        });
      }
    },
    nextStep() {
      this.step += 1;
      this.fields = this[`step${this.step + 1}_fields`] || [];
      this.fields.forEach((val) => {
        if (val.type === 'otp') {
          this.$set(this.showKeyboard, val.field_name, false);
        }
        this.$set(this.form, val.field_name, '');
      });
      this.fields = this.fields.map((v) => {
        v.rules = [{ required: v.is_required, message: 'Kolom ini wajib diisi' }];
        if (v.custom_rules) {
          v.rules.push(v.custom_rules);
        }
        return v;
      });
    },
    async nextStepHandler(current_step) {
      if (current_step === 0) {
        if (!this.show_otp_register) {
          this.form.otp = '';
          this.show_otp_register = true;
          this.showKeyboard.otp_register = true;
          await this.triggerRequestOTPRegister();
        } else {
          await this.handlerRegisterMerchant();
        }
        return;
      }
      if (current_step === 1) {
        await this.handlerRegisterWallet();
        return;
      }
      this.nextStep();
    },
    onInput(value, field_name) {
      this.form[field_name] += value.toString();
    },
    onDelete(field_name) {
      const f = this.fields.find((v) => v.field_name === field_name);
      if (f.deleteable_from && (this.form[field_name].length - 1) > f.deleteable_from) {
        this.form[field_name] = this.form[field_name].slice(0, this.form[field_name].length - 1);
      } else if (!f.deleteable_from) {
        this.form[field_name] = this.form[field_name].slice(0, this.form[field_name].length - 1);
      }
    },
    handlerShowHidePassword(index) {
      this.fields[index].is_visible_password = !this.fields[index].is_visible_password;
      this.fields[index].type = this.fields[index].is_visible_password ? 'text' : 'password';
    },
    onInputOTPRegister(value) {
      console.log(value);
      this.form.otp += value.toString();
    },
    onDeleteRegister() {
      this.form.otp = this.form.otp.slice(0, this.form.otp.length - 1);
    },
    handlerResendAction(function_name) {
      if (!function_name) {
        return;
      }
      this[function_name]();
    },
    startTimer() {
      let second = 60;
      this.timer = setInterval(() => {
        second -= 1;
        if (second) {
          this.waitingSecond = `${second} detik`;
        } else {
          this.waitingSecond = null;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    addBodyHeight() {
      document.body.style.height = `${document.getElementById('app').clientHeight + 180}px`;
    },
    subtractBodyHeight() {
      document.body.style.height = `${document.getElementById('app').clientHeight - 180}px`;
    },
    checkValidOTP() {
      return this.isValidOtpRegister;
    },
  },
};
</script>
<style>
.btn-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.v-form-title {
  margin: 0;
  padding: 32px 16px 16px;
  /* color: rgba(69, 90, 100, 0.6); */
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.floating-cta {
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0px -4px 4px 0px #E2E2EE;
  position: fixed;
  padding: 12px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.main-cta {
  padding: 12px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.37px;
  background-color: #FB7418;
  border-radius: 8px;
  display: block;
  color: #ffffff;
  font-weight: 600;
}
.van-toast {
  width: 188px;
}
</style>